
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/*  styling scrollbars  */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #20409A;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.logo-container {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-jali-group{
  width: 70px;
  /* height: 100px; */
  object-fit: cover;
}
.profile_container {
  border-radius: 5px 0px 0px 0px;
  width: 30%;
  height: 100%;
}

.permit_container {
  width: 30%;
  height: 150px;
}
.permit_container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo-container img {
  object-fit: cover;
}

.rounded-name {
  border-radius: 0px 5px 0px 0px;
}
.active{
  color: #20409A;
}
.Inactive{
  color: #cc3d38;
}
.Contract_terminated{
  color: #0b0101;
}

.rounded-footer {
  border-radius: 0px 0px 5px 5px;
}

.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-title {
  color: #2e4a66;
}

.text-lightblue {
  color: #469cac;
}

.create_btn {
  background-color: #20409A;
  color: white;
}
.update-btn{
  background-color: #20409A;
  color: white;
}
.update-input{
width: 100%;
}
.dropdown:hover .dropdown-menu {
  display: block;
}

.activeTab {
  border-radius: 5px;
  background-color: #20409A;
  color: white;
  border: 1px solid #20409A;
}

.text-red {
  color: #cc3d38;
}

.max-w-lg {
  max-width: 43rem !important;
}

.text-yellow {
  color: #a98921;
}
.receipt-image{
  width: 50%;
}
.receipt-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.receipt-owner{
  width: 50%;
}
.user-container{
  width: 70%;
}
.receipt-container{
width: 100%;
}
.filter-width{
  width: 100px;
  height: 40px;
}
.total_debt .high{
  width: 100%;
  background-color: #f05b24;
}
.total_debt .middle{
  width: 100%;
  background-color: rgb(255, 225, 27);
}
.total_debt .below{
  width: 100%;
  background-color: #46bc98;
}
.current_debt .high{
  width: 100%;
  background-color: #f05b24;
}
.current_debt .middle{
  width: 100%;
  background-color: rgb(255, 225, 27);
}
.current_debt .below{
  width: 100%;
  background-color: #46bc98;
}
.current_days_late .below{
  width: 100%;
  background-color: #46bc98;
}
.current_days_late .high{
  width: 100%;
  background-color:#f05b24;
}
.total_days_late .below{
  width: 50%;
  background-color: #46bc98;
}
.total_days_late .high{
  width: 100%;
  background-color:#f05b24;
}

.text-green {
  color: #20409A;
}
.applications-search{
  width: 30%;
}
.filter-container {
  width: 90%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #20409A;


}
.active_filter{
  background-color: #20409A;
  color: white;
}
.style-select {
  position: absolute;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate3d(522.5px, 3847.5px, 0px);

}

.container {
  width: 93%;
}

.date-filter input {
  width: 90%;
}

.card-title {
  font-size: 13px;
  color: #5F6980;
}

.card-title-sub {
  font-size: 15px;
  font-weight: bold;
  color: #282828;
}

.card-title-footer {
  color: #20409A;
  font-size: 13px;

}
.banner_login_mobile{
  display: none;
}
.profile-container{
  width: 14%;
}

@media only screen and (max-width: 500px) and (min-width: 320px) {

  .banner_login{
    display: none !important;
  }

  .banner_login_mobile{
    display: block;
  }

  .login-container{
    width: 100%;
  }

  .login-container-form{
    width: 90%;
  }


}



/* mine css */
.login-screen {
  height: 100vh;
  background-color: #E5E5E5;
}

.login-page {
  border-radius: 10px;
  position: relative;
  top: 0;
  /* height: 70%; */
  width: 90%;
}

.image_footer {
  width: 100%;
}

.footer {
  /* margin-bottom: -50px; */
  width: 100%;
}

.banner_login {
  display: flex;
  justify-content: center;
  align-items: center;

}

.login_form {
  width: 100%;
  width: 100%;
}
.ant-select .ant-select-selection-item{
  font-size: 12px;

}
.ant-select-selector{
  height: 35px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector  .ant-select-selector{
  height: 24px;
}
.ant-picker .ant-picker-input >input {
font-size: 12px;
height: 25px;
}

.logo_height img {
  width: 90%;
  height: 100%;
  object-fit: cover;
  position: absolute;

}

.login-title {
  font-weight: 700;
  font-size: 20px;
  color: #23419B;
}

.login-sub-title {
  font-weight: 400;
  font-size: 14px;
  color: #23419B;
}

label {
  font-size: 13px !important;
  color: #cac9c9;
  padding: 8px 0px;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name {
color: #469cac;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-icon .anticon{
  color: #469cac;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon{
  color: #469cac;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon:hover{
  color: red;
}
.ant-modal .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger){
  background-color: #20409A;
  color: white;
}

.ant-switch {
  background-color: #20409A;
height: 30px;
}
.ant-btn-primary:disabled{
  color: #ffff;
}
.ant-switch .ant-switch-handle{
  top: 6px !important;
}
.ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
  margin-top: -19px;
}.ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked{
  margin-top: 4px;
}
.ant-switch.ant-switch-checked {
  background: #469cac;
}
.text-sm{
  font-size: 11px;

}
.kwemeza-section{
  font-size: 13px;
}

input {
  border: 1px solid #828282;
  padding: 3px 5px;
  font-size: 12px;
  border-radius: 5px;
  height: 36px;
}
select{

  padding: 3px 5px;
  font-size: 12px;
  border-radius: 5px;
  height: 38px;
}
::placeholder {
  color: #946EC4;
}

.remeber_forgot {
  font-weight: 400;
  font-size: 12px;
  color: #946EC4;

}

.fom-btn {
  background: #20409A;
  border-radius: 6px;
  color: white;
}

.page-content {
  width: 100%;
}

/* upload image css */
.upload_container {
  position: relative;
  width: 28%;
  height: 200px;
}

.user_info {
  width: 70%;
}

.image {
  width: 100%;
  cursor: pointer;
  height: 100%;
  object-fit: cover;
  border: 1px solid #F5F7F8;
  background-color: #F5F7F8;
  border-radius: 6px;
}

.radio {
  border: 1px solid #F5F7F8 !important;
  height: 20px !important;
}

.legend {
  color: #334154;
  font-size: 11px;
}

.fieldset {
  width: 50%;
  height: 100%;
}

.form_control input {
  width: 100%;
  height: 40px;
  border: 1px solid #dee3e5;

}

input {
  border: 1px solid #dee3e5;
}

.marital {
  width: 30%;
  height: 100%;
}

.multiselect-container {}

.multiSelectContainer input {
  height: 10px !important;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid #F5F7F8;
}

.user_info input {
  width: 100%;
  border: 1px solid #dee3e5;

}

.label {
  display: block;
  width: 100%;
  height: 100%;
}

.input-hidden {
  display: none;
}

.my-select__control {
  border: 1px solid #dee3e5 !important;
  border-radius: 4px;
  height: 25px;
}

.css-1u9des2-indicatorSeparator {
  height: 38%;
}

.css-qbdosj-Input {
  margin: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;


}

.my-select__menu {
  color: black !important;
  background-color: white;
  z-index: 9999 !important;
}

.my-select__option {
  color: black !important;
  background-color: white;
}

.my-select__option--is-selected {
  color: white !important;
  background-color: #007bff !important;
}

.home_banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 30rem;
 
}

.register_banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 15rem;
}

.register-container {
  margin-top: 76px;
}

.banner-content {
  border-radius: 9px;
  background: linear-gradient(to right, #23419B 28%, rgba(35, 65, 155, 0) 100%);
}
.jali-logo{
  width: 100px;
  height: 100px;
  object-fit: cover;
}

tbody tr td {
  font-size: 12px;
  padding: 5px 4px !important;
  color: #9fa3a9;
}

tbody tr th {
  font-size: 15px;
  color: #e5e7eb;
  padding: 20px 4px !important;
}