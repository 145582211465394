#sidebar {
    background: white;
    grid-area: sidebar;
    overflow-y: auto;
    padding: 20px;
    width: 15%;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  
  .sidebar__title {
    margin-bottom: 30px;
  }
  
  .sidebar__img {
    display: flex;
    align-items: center;
  }
  
  .sidebar__title > div > img {
 
    object-fit: contain;
  }
  
  .sidebar__title > div > h1 {
    font-size: 18px;
    display: inline;
  }
  
  .sidebar__title > i {
    font-size: 10px;
    display: none;
  }
  
  .sidebar__menu > h2 {
    color: #20409A;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 0 10px;
    font-weight: 700;
  }
  
  .sidebar__link {
    color: #282828;
    font-size: 12px;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  
  .active_menu_link {
   font-size: 15px;
   font-weight: bold;
    color: #20409A;
  }
  
  .active_menu_link a {
    color: #20409A !important;
  }
  
  .sidebar__link > a {
    text-decoration: none;
    color: #a5aaad;
    font-weight: 700;
  }
  
  .sidebar__link > i {
    margin-right: 10px;
    font-size: 18px;
  }
  
  .sidebar__logout {
    margin-top: 20px;
    padding: 10px;
    color: #e65061;
  }
  
  .sidebar__logout > a {
    text-decoration: none;
    color: #e65061;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .sidebar__logout > i {
    margin-right: 10px;
    font-size: 18px;
  }
  
  .sidebar_responsive {
    display: inline !important;
    z-index: 9999 !important;
    left: 0 !important;
    position: absolute;
  }
  
  @media only screen and (max-width: 978px) {
    #sidebar {
      display: none;
    }
  
    .sidebar__title > i {
      display: inline;
    }
  }